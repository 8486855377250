<template>
  <div>
    <!-- {{ d_project }} -->
    <!-- {{ d_project.details }} -->
    <!-- {{ p_idType }} -->
    <b-spinner v-if="d_projectDetailsLoading" variant="primary" style="width: 30px; height: 30px;" label="Searching"></b-spinner>
    <b-row v-if="d_screenModeChangeAvailable" style="margin-bottom: 10px;">
      <b-col sm="12" lg="9">
      </b-col>
      <b-col sm="12" lg="3">
        <b-form-select v-model="d_screen_mode">
          <option value="1"> {{ StoreLangTranslation.data['list_mode'][StoreLang] }}</option>
          <option value="2"> {{ StoreLangTranslation.data['navigation_mode'][StoreLang] }} </option>
        </b-form-select>
      </b-col>
    </b-row>
    <!-- large screen mode -->
      <div v-if="d_screen_mode === '1'" style="font-weight: 700; font-size: 12px; padding: 3px;">
        <b-row style="margin-bottom: 10px;">
          <b-col cols="12">
            <strong>{{ d_project.details.name }}</strong>
          </b-col>
        </b-row>
        <b-row v-if="d_project.details.about" style="margin-bottom: 10px;">
          <b-col cols="12">
            <i>{{ d_project.details.about }}</i>
          </b-col>
        </b-row>
        <template v-if="d_clientIdShow && d_idType === 'client_id_based' || d_idType === 'based_on_client_id_or_wdmr_id'">
          <b-row style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
            <b-col sm="12" lg="2">
              <strong> <i class="fa fa-edit"></i> Id </strong>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-input type="text" style="font-size: 12px;" v-model="d_clientId" :placeholder="StoreLangTranslation.data['please_input_id'][StoreLang]"></b-form-input>
            </b-col>
            <b-col sm="12" lg="6">
              <template v-if="d_project.details.project_data_type && d_project.details.project_data_type.value === 'mssql' && d_showSqlName && d_idType === 'client_id_based'">
                <b-row>
                  <b-col sm="12" lg="6">
                    <strong> <i class="fa fa-edit"></i> {{ StoreLangTranslation.data['data_unit_sql'][StoreLang] }} </strong>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-input type="text" v-model="d_sqlName" :placeholder="StoreLangTranslation.data['please_input_sql_name_database_unit'][StoreLang]"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_clientIdShow && d_idType === 'based_on_wdmr_id' || d_idType === 'based_on_client_id_or_wdmr_id'">
          <b-row style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
            <b-col sm="12" lg="2">
              <strong> <i class="fa fa-edit"></i> Wdmr Id </strong>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-input type="text" style="font-size: 12px;" v-model="d_wdmrId" :placeholder="StoreLangTranslation.data['please_input_id'][StoreLang]"></b-form-input>
            </b-col>
            <b-col sm="12" lg="6">
            </b-col>
          </b-row>
        </template>
        <b-row v-if="d_isThereInputColumn">
          <b-col sm="12" :lg="d_isThereRightAnyLocationColumn ? 6 : 12">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col cols="4">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['inputs'][StoreLang] }}
                  </b-col>
                  <b-col cols="8" style="text-align: right;">
                    <b-spinner v-if="d_analyzeContinuing" variant="primary" style="width: 20px; height: 20px; margin-right: 10px;" label="Continuing"></b-spinner>
                    <b-button v-if="d_project.column_list.length > 0" variant="white" size="sm" @click="f_resetForm()" style="background: #ffb8b8;">
                      <i class="fa fa-trash" style="color: red;"></i> {{ StoreLangTranslation.data['reset'][StoreLang] }}
                    </b-button>
                    <template v-if="!d_isThereRightAnyLocationColumn">
                      <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                      <b-button variant="white" size="sm" @click="f_analyzeIfNotAnalyzeContinuing()" style="background: #b8ffdf;">
                        <i class="fa fa-flash" style="color: green;"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                      </b-button>
                    </template>
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-for="(col, col_index) in d_project.column_list">
                <b-row v-if="col.wai_input === 1" style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
                  <b-col cols="12">
                    <b-row>
                      <b-col sm="12" md="6" lg="6">
                        <b-row>
                          <b-col sm="12" md="12" lg="12">
                            <strong> <i class="fa fa-edit"></i> {{ col.name.length > 40 ? col.name.substring(0 , 40) + '...' : col.name }} </strong>
                            <i v-if="col.info" class="fa fa-info-circle" :title="col.info" style="color: blue;" @click="f_showTextAsModal(col.info)"></i>
                          </b-col>
                        </b-row>
                        <b-row v-if="col.image" style="padding: 3px;">
                          <b-col sm="12" md="12" lg="12">
                            <img :src="col.image" style="width: 100%;">
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="12" md="6" lg="6" style="text-align: center;">
                        <template v-if="['radiogroup', 'select'].indexOf(col.param_type) !== -1">
                          <b-form-select style="font-size: 12px;" v-model="col.val">
                            <option v-for="(op, op_ind) in col.options" :value="op">
                              {{ op.label }}
                            </option>
                          </b-form-select>
                        </template>
                        <template v-else>
                          <b-form-input type="number" style="font-size: 12px;" v-model="col.val"></b-form-input>
                        </template>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
          <b-col sm="12" lg="6" v-if="d_isThereRightAnyLocationColumn">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['outputs'][StoreLang] }}
                  </b-col>
                  <b-col cols="6" style="text-align: right;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                    <b-button v-if="!this.d_analyzeContinuing" variant="white" size="sm" @click="f_analyzeIfNotAnalyzeContinuing()" style="background: #b8ffdf;">
                      <i class="fa fa-flash" style="color: green;"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-for="(col, col_index) in d_project.column_list">
                <b-row v-if="f_controlWaiOutputMode(col, 'right')" style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
                  <b-col cols="12">
                    <b-row>
                      <b-col sm="12" md="6" lg="6">
                        <b-row>
                          <b-col sm="12" md="12" lg="12">
                            <strong> <i class="fa fa-check"></i> {{ col.name.length > 40 ? col.name.substring(0 , 40) + '...' : col.name }} </strong>
                            <i v-if="col.info" class="fa fa-info-circle" :title="col.info" style="color: blue;" @click="f_showTextAsModal(col.info)"></i>
                          </b-col>
                        </b-row>
                        <b-row v-if="col.image" style="padding: 3px;">
                          <b-col sm="12" md="12" lg="12">
                            <img :src="col.image" style="width: 100%;">
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="12" md="6" lg="6" style="min-height: 30px;">
                        <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                        <div style="overflow-x: auto; overflow-y: auto; max-height: 300px;">
                          <template v-if="col.obj">
                            <template v-for="(obj_data, obj_data_id) in col.obj">
                              <template v-for="(li, li_index) in obj_data.list">
                                <div :style="f_calculateColumnStyle(col)">
                                  <template v-if="typeof(li.val) === 'object'">
                                    <template v-if="li.link">
                                      <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                    </template>
                                    <template v-else>
                                      <strong>{{ li.val.label }}</strong>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <template v-if="li.link">
                                      <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                    </template>
                                    <template v-else>
                                      <strong>{{ li.val }}</strong>
                                    </template>
                                  </template>
                                </div>
                                <div v-if="li.info" v-html="li.info"></div>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            ...
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <b-card-header v-if="!d_isThereInputColumn && d_isAnalyzed" class="p-1" header-bg-variant="white">
          <b-row>
            <b-col cols="6" style="text-align: right;"></b-col>
            <b-col cols="6" style="text-align: right;">
              <b-button variant="white" size="sm" @click="f_analyzeIfNotAnalyzeContinuing()" style="background: #b8ffdf; margin-right: 5px;">
                <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em; margin-right: 10px;" /> -->
                <i class="fa fa-flash" style="color: green;"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
              </b-button>
              <b-button variant="white" size="sm" :style="d_timeLineMode ? 'background: #b8ffdf; margin-right: 5px;' : 'margin-right: 5px;'" @click="d_timeLineMode ? d_timeLineMode = false : d_timeLineMode = true;">
                <i class="fa fa-calendar"></i> Zaman Çizelgesi
              </b-button>
              <b-button v-if="d_project.column_list.length > 0" variant="white" size="sm" @click="f_resetForm()" style="background: #ffb8b8; margin-right: 5px;">
                <i class="fa fa-trash" style="color: red;"></i> {{ StoreLangTranslation.data['reset'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row v-if="!d_isThereInputColumn && !d_timeLineMode && d_isAnalyzed">
          <b-col sm="12" md="12" lg="12">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['outputs'][StoreLang] }}
                  </b-col>
                  <b-col cols="6" style="text-align: right;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-for="(col, col_index) in d_project.column_list">
                <b-row v-if="f_controlWaiOutputMode(col, 'right')" style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
                  <b-col cols="12">
                    <b-row>
                      <b-col sm="12" md="3" lg="3">
                        <b-row>
                          <b-col sm="12" md="12" lg="12">
                            <strong> <i class="fa fa-check"></i> {{ col.name.length > 40 ? col.name.substring(0 , 40) + '...' : col.name }} </strong>
                            <i v-if="col.info" class="fa fa-info-circle" :title="col.info" style="color: blue;" @click="f_showTextAsModal(col.info)"></i>
                          </b-col>
                        </b-row>
                        <b-row v-if="col.image" style="padding: 3px;">
                          <b-col sm="12" md="12" lg="12">
                            <img :src="col.image" style="width: 100%;">
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="12" md="9" lg="9" style="min-height: 30px;">
                        <div style="overflow-x: auto; overflow-y: auto; max-height: 300px;">
                          <template v-if="col.obj">
                            <!-- {{ col.obj_order }} -->
                            <!-- <template v-for="(obj_data, obj_data_id) in col.obj"> -->
                            <!-- <template v-for="(obj_data_key, obj_data_key_index) in Object.keys(col.obj).sort()"> -->
                            <template v-for="(obj_data_key, obj_data_key_index) in col.obj_order">
                              <template v-for="(li, li_index) in col.obj[obj_data_key].list">
                                <div :style="obj_data_key_index % 2 !== 0 ? 'background-color: #e8f7fa;' : ''">
                                  <b-row :style="f_calculateColumnStyle(col)">
                                    <b-col sm="12" lg="3">{{ col.obj[obj_data_key].date ? f_dateFormat(col.obj[obj_data_key].date, 'year-month-day hh:mm') : '' }}</b-col>
                                    <b-col sm="12" lg="9">
                                      <template v-if="typeof(li.val) === 'object'">
                                        <template v-if="li.link">
                                          <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                        </template>
                                        <template v-else>
                                          <strong>{{ li.val.label }}</strong>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <template v-if="li.link">
                                          <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                        </template>
                                        <template v-else>
                                          <strong>{{ li.val }}</strong>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                                </div>
                                <div v-if="li.info" v-html="li.info"></div>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            ...
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <time-line v-if="!d_isThereInputColumn && d_timeLineMode && d_timeLineLeftToRight.length > 0" :timeline_data="d_timeLineLeftToRight" :year_month_mode="d_timeLineYearMonthMode" :mother_children="mother_children"></time-line>
        <!-- Sub wai output cards -->
        <template v-for="(col, col_index) in d_project.column_list">
          <b-row v-if="f_controlWaiOutputMode(col, 'bottom')" style="margin-top: 10px;">
            <b-col cols="12">
              <b-card no-body>
                <b-card-header class="p-1" header-bg-variant="white">
                  <b-row>
                    <b-col cols="6">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                      {{ col.name }}
                    </b-col>
                    <b-col cols="6" style="text-align: right;"></b-col>
                  </b-row>
                </b-card-header>
                <b-row v-if="col.image" style="padding: 3px;">
                  <b-col sm="12" md="12" lg="12">
                    <img :src="col.image" style="width: 100%;">
                  </b-col>
                </b-row>
                <b-row style="margin: 3px;">
                  <b-col sm="12" md="12" lg="12" style="min-height: 30px;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                    <template v-if="col.obj && !d_analyzeContinuing">
                      <template v-for="(obj_data, obj_data_id) in col.obj">
                        <template v-for="(li, li_index) in obj_data.list">
                          <div :style="f_calculateColumnStyle(col)">
                            <b-row v-if="typeof(li.val) === 'object'">
                              <b-col cols="12">
                                <template v-if="li.link">
                                  <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                </template>
                                <template v-else>
                                  <strong>{{ li.val.label }}</strong>
                                </template>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col sm="12" md="12" lg="12">
                                <template v-if="li.link">
                                  <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                </template>
                                <template v-else>
                                  <strong>{{ li.val }}</strong>
                                </template>
                              </b-col>
                            </b-row>
                          </div>
                          <b-card no-body v-if="li.info">
                            <b-card-header class="p-1" header-bg-variant="white">
                              <b-row>
                                <b-col cols="6">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                                  Bilgi
                                </b-col>
                                <b-col cols="6" style="text-align: right;"></b-col>
                              </b-row>
                            </b-card-header>
                            <b-card-body>
                              <div v-html="li.info"></div>
                            </b-card-body>
                          </b-card>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      ...
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </div>
    <!-- screen mode 2 -->
      <div v-if="d_screen_mode === '2'" style="padding: 3px;">
        <b-row style="margin-bottom: 10px;">
          <b-col cols="12">
            <h4>{{ d_project.details.name }}</h4>
          </b-col>
        </b-row>
        <hr/>
        <b-row v-if="d_project.details.about" style="margin-bottom: 20px;">
          <b-col cols="12">
            <small>{{ d_project.details.about }}</small>
          </b-col>
        </b-row>
        <template v-if="d_clientIdShow && d_idType === 'client_id_based' || d_idType === 'based_on_client_id_or_wdmr_id'">
          <b-row style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
            <b-col sm="12" lg="2">
              <strong> <i class="fa fa-edit"></i> Id </strong>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-input type="text" style="font-size: 12px;" v-model="d_clientId" :placeholder="StoreLangTranslation.data['please_input_id'][StoreLang]"></b-form-input>
            </b-col>
            <b-col sm="12" lg="6">
              <template v-if="d_project.details.project_data_type && d_project.details.project_data_type.value === 'mssql' && d_showSqlName && d_idType === 'client_id_based'">
                <b-row>
                  <b-col sm="12" lg="6">
                    <strong> <i class="fa fa-edit"></i> {{ StoreLangTranslation.data['data_unit_sql'][StoreLang] }} </strong>
                  </b-col>
                  <b-col sm="12" lg="6">
                    <b-form-input type="text" v-model="d_sqlName" :placeholder="StoreLangTranslation.data['please_input_sql_name_database_unit'][StoreLang]"></b-form-input>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
        </template>
        <template v-if="d_clientIdShow && d_idType === 'based_on_wdmr_id' || d_idType === 'based_on_client_id_or_wdmr_id'">
          <b-row style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
            <b-col sm="12" lg="2">
              <strong> <i class="fa fa-edit"></i> Wdmr Id </strong>
            </b-col>
            <b-col sm="12" lg="4">
              <b-form-input type="text" style="font-size: 12px;" v-model="d_wdmrId" :placeholder="StoreLangTranslation.data['please_input_id'][StoreLang]"></b-form-input>
            </b-col>
            <b-col sm="12" lg="6">
            </b-col>
          </b-row>
        </template>
        <b-row v-if="d_isThereInputColumn && d_showInputs">
          <b-col sm="12" md="12" lg="12">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col sm="12" lg="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['inputs'][StoreLang] }}
                    {{ d_currentColIndex + 1 }} / {{ d_waiInputColIndexList.length }}
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button v-if="d_currentColIndex != 0 && !d_analyzeContinuing" size="sm" variant="outline-primary" @click="d_currentColIndex --" style="width: 100%; margin: 5px;">
                      <i class="fa fa-angle-left"></i> {{ StoreLangTranslation.data['prev'][StoreLang] }}
                    </b-button>
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button v-if="d_currentColIndex != d_waiInputColIndexList.length - 1 && d_project.column_list[d_waiInputColIndexList[d_currentColIndex]] && d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val && !d_analyzeContinuing && !d_columnInputWait" size="sm" variant="outline-primary" @click="d_currentColIndex ++" style="width: 100%; margin: 5px;">
                      {{ StoreLangTranslation.data['next'][StoreLang] }} <i class="fa fa-angle-right"></i> 
                    </b-button>
                  </b-col>
                  <b-col sm="12" lg="2" style="text-align: right;">
                    <b-button v-if="d_project.column_list.length > 0 && !d_analyzeContinuing" variant="outline-primary" size="sm" @click="f_resetForm()" style="width: 100%; margin: 5px;">
                      <i class="fa fa-angle-double-left"></i> {{ StoreLangTranslation.data['go_to_start'][StoreLang] }}
                    </b-button>
                    <b-spinner v-else variant="primary" label="Continuing" style="margin: 2px;"></b-spinner>
                  </b-col>
                </b-row>
              </b-card-header>
              <template>
                <b-row v-if="d_waiInputColIndexList.length && d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].wai_input === 1" style="margin: 3px; padding: 1px; min-height: 30px;">
                  <b-col cols="12">
                    <b-row v-if="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].image" style="margin: 3px;">
                      <b-col sm="12" md="12" lg="12">
                        <img :src="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].image" style="width: 300px;">
                      </b-col>
                    </b-row>
                    <b-row  style="margin: 3px;">
                      <b-col sm="12" lg="12">
                        <strong> <i class="fa fa-edit"></i> {{ d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].name }} </strong>
                        <!-- <i v-if="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].info" class="fa fa-info-circle" :title="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].info" style="color: blue;" @click="f_showTextAsModal(d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].info)"></i> -->
                      </b-col>
                    </b-row>
                    <b-row v-if="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].info" style="margin: 3px;">
                      <b-col sm="12" lg="12">
                        <i style="padding-left: 20px; font-size: 12px;"> ( {{ d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].info }} )</i>
                      </b-col>
                    </b-row>
                    <b-row style="margin: 20px;">
                      <b-col sm="12" lg="12">
                        <template v-if="['radiogroup', 'select'].indexOf(d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].param_type) !== -1">
                          <template v-if="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].options.length <= 6">
                            <template v-for="(op, op_ind) in d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].options">
                              <b-row style="margin: 3px;">
                                <b-col sm="12" lg="2">
                                </b-col>
                                <b-col sm="12" lg="8">
                                  <b-button @click="f_columnButtonAndNextIndex(op)" style="border-radius: 15px; width: 100%; margin: 10px; border: solid 1px grey;" :variant="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val && op.value == d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val.value ? 'info' : 'white'">
                                    <i v-if="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val && op.value == d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val.value" class="fa fa-check"></i>
                                    {{ op.label }}
                                  </b-button>
                                </b-col>
                                <b-col sm="12" lg="2">
                                </b-col>
                              </b-row>
                            </template>
                          </template>
                          <template v-else>
                            <b-form-select style="width: 100%; margin: 0 auto;" v-model="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val" @change="f_nextIndex(d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val)">
                              <option v-for="(op, op_ind) in d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].options" :value="op">
                                {{ op.label }}
                              </option>
                            </b-form-select>
                          </template>
                        </template>
                        <template v-else>
                          <b-row style="margin: 3px;">
                            <b-col sm="12" lg="6">
                              <b-form-input type="number" v-model="d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val"></b-form-input>
                            </b-col>
                            <b-col sm="12" lg="6">
                              <b-button @click="f_nextIndex(d_project.column_list[d_waiInputColIndexList[d_currentColIndex]].val)" variant="outline-primary" style="width: 100%;">
                                <i class="fa fa-long-arrow-right"></i>
                              </b-button>
                            </b-col>
                          </b-row>
                        </template>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
              <template v-if="d_isAnalyzed && d_showInputs">
                <hr style="margin: 0;" />
                <b-row style="margin: 3px;">
                  <b-col sm="12" lg="10">
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button variant="outline-primary" size="sm" @click="f_analyzeIfNotAnalyzeContinuing()" style="width: 100%; margin: 5px;">
                      <i class="fa fa-flash"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="!d_showInputs" style="margin-top: 10px;">
          <b-col sm="12" lg="12">
            <b-card no-body>
              <b-card-header header-bg-variant="white">
                <b-row>
                  <b-col sm="12" lg="10">
                    {{ StoreLangTranslation.data['results'][StoreLang] }} {{ d_waiInputColIndexList.length }} / {{ d_waiInputColIndexList.length }}
                  </b-col>
                  <b-col sm="12" lg="2">
                    <b-button variant="outline-info" style="width: 100%;" @click="d_showInputs = true;">
                      <i class="fa fa-edit" ></i>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-if="d_isAnalyzed && d_isThereRightAnyLocationColumn">
          <b-col sm="12" md="12" lg="12">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['outputs'][StoreLang] }}
                  </b-col>
                  <b-col cols="6" style="text-align: right;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                    <!-- <b-button variant="white" size="sm" @click="f_wanalyzerProjectCellCalculate()" style="background: #b8ffdf;">
                      <i class="fa fa-flash" style="color: green;"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
                    </b-button> -->
                    <b-button v-if="d_project.column_list.length > 0" variant="outline-primary" size="sm" @click="f_resetForm()" style="margin-right: 5px;">
                      <i class="fa fa-angle-double-left"></i> {{ StoreLangTranslation.data['go_to_start'][StoreLang] }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-header>
                <template v-for="(col, col_index) in d_project.column_list">
                  <b-row v-if="f_controlWaiOutputMode(col, 'right')" style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
                    <b-col cols="12">
                      <b-row style="margin: 3px;">
                        <b-col sm="12" md="6" lg="6">
                          <b-row>
                            <b-col sm="12" md="12" lg="12">
                              <strong> <i class="fa fa-check"></i> {{ col.name.length > 40 ? col.name.substring(0 , 40) + '...' : col.name }} </strong>
                              <i v-if="col.info" class="fa fa-info-circle" :title="col.info" style="color: blue;" @click="f_showTextAsModal(col.info)"></i>
                            </b-col>
                          </b-row>
                          <b-row v-if="col.image" style="padding: 3px;">
                            <b-col sm="12" md="12" lg="12">
                              <img :src="col.image" style="width: 100%;">
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="12" md="6" lg="6" style="min-height: 30px;">
                          <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                          <div style="overflow-x: auto; overflow-y: auto; max-height: 300px;">
                            <template v-if="col.obj">
                              <template v-for="(obj_data, obj_data_id) in col.obj">
                                <template v-for="(li, li_index) in obj_data.list">
                                  <div :style="f_calculateColumnStyle(col)">
                                    <template v-if="typeof(li.val) === 'object'">
                                      <template v-if="li.link">
                                        <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                      </template>
                                      <template v-else>
                                        <strong>{{ li.val.label }}</strong>
                                      </template>
                                    </template>
                                    <template v-else>
                                      <template v-if="li.link">
                                        <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                      </template>
                                      <template v-else>
                                        <strong>{{ li.val }}</strong>
                                      </template>
                                    </template>
                                  </div>
                                  <div v-if="li.info" v-html="li.info"></div>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              ...
                            </template>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
            </b-card>
          </b-col>
        </b-row>
        <b-card-header v-if="!d_isThereInputColumn && d_isAnalyzed" class="p-1" header-bg-variant="white">
          <b-row>
            <b-col cols="6" style="text-align: right;"></b-col>
            <b-col cols="6" style="text-align: right;">
              <!-- <b-button variant="white" size="sm" @click="f_wanalyzerProjectCellCalculate()" style="background: #b8ffdf; margin-right: 5px;">
                <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em; margin-right: 10px;" />
                <i class="fa fa-flash" style="color: green;"></i> {{ StoreLangTranslation.data['analyze'][StoreLang] }}
              </b-button> -->
              <b-button variant="white" size="sm" :style="d_timeLineMode ? 'background: #b8ffdf; margin-right: 5px;' : 'margin-right: 5px;'" @click="d_timeLineMode ? d_timeLineMode = false : d_timeLineMode = true;">
                <i class="fa fa-calendar"></i> Zaman Çizelgesi
              </b-button>
              <b-button v-if="d_project.column_list.length > 0" variant="outline-primary" size="sm" @click="f_resetForm()" style="margin-right: 5px;">
                <i class="fa fa-angle-double-left"></i> {{ StoreLangTranslation.data['go_to_start'][StoreLang] }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-row v-if="!d_isThereInputColumn && !d_timeLineMode && d_isAnalyzed">
          <b-col sm="12" md="12" lg="12">
            <b-card no-body>
              <b-card-header class="p-1" header-bg-variant="white">
                <b-row>
                  <b-col cols="6">
                    <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                    {{ StoreLangTranslation.data['outputs'][StoreLang] }}
                  </b-col>
                  <b-col cols="6" style="text-align: right;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                  </b-col>
                </b-row>
              </b-card-header>
              <template v-for="(col, col_index) in d_project.column_list">
                <b-row v-if="f_controlWaiOutputMode(col, 'right')" style="margin: 3px; padding: 1px; border-bottom: solid 0.5px skyblue; min-height: 30px;">
                  <b-col cols="12">
                    <b-row>
                      <b-col sm="12" md="3" lg="3">
                        <b-row>
                          <b-col sm="12" md="12" lg="12">
                            <strong> <i class="fa fa-check"></i> {{ col.name.length > 40 ? col.name.substring(0 , 40) + '...' : col.name }} </strong>
                            <i v-if="col.info" class="fa fa-info-circle" :title="col.info" style="color: blue;" @click="f_showTextAsModal(col.info)"></i>
                          </b-col>
                        </b-row>
                        <b-row v-if="col.image" style="padding: 3px;">
                          <b-col sm="12" md="12" lg="12">
                            <img :src="col.image" style="width: 100%;">
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="12" md="9" lg="9" style="min-height: 30px;">
                        <div style="overflow-x: auto; overflow-y: auto; max-height: 300px;">
                          <template v-if="col.obj">
                            <!-- {{ col.obj_order }} -->
                            <!-- <template v-for="(obj_data, obj_data_id) in col.obj"> -->
                            <!-- <template v-for="(obj_data_key, obj_data_key_index) in Object.keys(col.obj).sort()"> -->
                            <template v-for="(obj_data_key, obj_data_key_index) in col.obj_order">
                              <template v-for="(li, li_index) in col.obj[obj_data_key].list">
                                <div :style="obj_data_key_index % 2 !== 0 ? 'background-color: #e8f7fa;' : ''">
                                  <b-row :style="f_calculateColumnStyle(col)">
                                    <b-col sm="12" lg="3">{{ col.obj[obj_data_key].date ? f_dateFormat(col.obj[obj_data_key].date, 'year-month-day hh:mm') : '' }}</b-col>
                                    <b-col sm="12" lg="9">
                                      <template v-if="typeof(li.val) === 'object'">
                                        <template v-if="li.link">
                                          <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                        </template>
                                        <template v-else>
                                          <strong>{{ li.val.label }}</strong>
                                        </template>
                                      </template>
                                      <template v-else>
                                        <template v-if="li.link">
                                          <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                        </template>
                                        <template v-else>
                                          <strong>{{ li.val }}</strong>
                                        </template>
                                      </template>
                                    </b-col>
                                  </b-row>
                                </div>
                                <div v-if="li.info" v-html="li.info"></div>
                              </template>
                            </template>
                          </template>
                          <template v-else>
                            ...
                          </template>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </b-col>
        </b-row>
        <time-line v-if="!d_isThereInputColumn && d_timeLineMode && d_timeLineLeftToRight.length > 0" :timeline_data="d_timeLineLeftToRight" :year_month_mode="d_timeLineYearMonthMode" :mother_children="mother_children"></time-line>
        <!-- Sub wai output cards -->
        <template v-for="(col, col_index) in d_project.column_list">
          <b-row v-if="f_controlWaiOutputMode(col, 'bottom')" style="margin-top: 10px;">
            <b-col cols="12">
              <b-card no-body>
                <b-card-header class="p-1" header-bg-variant="white">
                  <b-row>
                    <b-col cols="12">
                      <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                      {{ col.name }}
                    </b-col>
                    <!-- <b-col cols="6" style="text-align: right;"></b-col> -->
                  </b-row>
                </b-card-header>
                <b-row v-if="col.image" style="padding: 3px;">
                  <b-col sm="12" md="12" lg="12">
                    <img :src="col.image" style="width: 100%;">
                  </b-col>
                </b-row>
                <b-row style="margin: 3px;">
                  <b-col sm="12" md="12" lg="12" style="min-height: 30px;">
                    <!-- <img v-if="d_analyzeContinuing" src="@/icon/sandwatch.gif" class="img-rounded img-responsive" style="width: 1.5em;" /> -->
                    <template v-if="col.obj">
                      <template v-for="(obj_data, obj_data_id) in col.obj">
                        <template v-for="(li, li_index) in obj_data.list">
                          <div :style="f_calculateColumnStyle(col)">
                            <b-row v-if="typeof(li.val) === 'object'">
                              <b-col cols="12">
                                <template v-if="li.link">
                                  <a :href="li.link" target="_blank"><strong>{{ li.val.label }}</strong></a>
                                </template>
                                <template v-else>
                                  <strong>{{ li.val.label }}</strong>
                                </template>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col sm="12" md="12" lg="12">
                                <template v-if="li.link">
                                  <a :href="li.link" target="_blank"><strong>{{ li.val }}</strong></a>
                                </template>
                                <template v-else>
                                  <strong>{{ li.val }}</strong>
                                </template>
                              </b-col>
                            </b-row>
                          </div>
                          <b-card no-body v-if="li.info">
                            <b-card-header class="p-1" header-bg-variant="white">
                              <b-row>
                                <b-col cols="6">
                                  <img src="@/icon/2448909.png" class="img-rounded img-responsive" style="width: 1.5em;" />
                                  Bilgi
                                </b-col>
                                <b-col cols="6" style="text-align: right;"></b-col>
                              </b-row>
                            </b-card-header>
                            <b-card-body>
                              <div v-html="li.info"></div>
                            </b-card-body>
                          </b-card>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      ...
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </div>
    <!-- info modal -->
      <b-modal v-if="d_showTableCellFullText" id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer title="Bilgi" centered header-bg-variant="info" header-text-variant="dark">
        <p class="my-4" style="word-wrap: break-word;">
          {{ d_selectedTableCellFullText }}
        </p>
      </b-modal>
  </div>
</template>

<script>
import {
  default as TimeLine
} from '@/components/widgets/TimeLine';
import moment from "moment";
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
export default {
  name: 'WanalyzerCellCalculate',
  components: {
    TimeLine
  },
  props: {
    p_projectId: {
      type: String,
      required: false
    },
    p_idType: {
      type: String,
      required: false
    },
    p_authClientId: {
      type: String,
      required: false
    },
    p_screenMode: {
      type: String,
      required: false
    },
    p_screenModeChange: {
      type: String,
      required: false
    }
  },
  data: () => {
    return {
      d_timeLineYearMonthMode: { 'year': 0, 'month': 0 },
      d_timeLineLeftToRight: [],
      mother_children: {
        'change_status': 0,
        'forceUpdate': 0
      },
      d_timeLineMode: false,
      d_isThereInputColumn: false,
      d_selectedTableCellFullText: '',
      d_showTableCellFullText: false,
      d_analyzeContinuing: false,
      d_sqlName: '',
      d_showSqlName: true,
      d_clientIdShow: true,
      d_project: { 'details': '', 'column_list': [] },
      d_clientId: '',
      d_projectId: '',
      d_idType: '',
      d_wdmrId: '',
      d_screen_mode: '2',
      d_currentColIndex: 0,
      d_waiInputColIndexList: [],
      d_isAnalyzed: false,
      d_isThereRightAnyLocationColumn: false,
      d_iframeMode: false,
      d_showInputs: true,
      d_columnList: [],
      d_columnInputWait: false,
      d_screenModeChangeAvailable: true,
      d_projectDetailsLoading: false
    }
  },
  computed: {
    ...mapGetters({
      StoreLangTranslation: 'StoreLangTranslation',
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  created () {
    if (this.$route.meta.iframe_mode === 'yes') {
      this.d_iframeMode = true;
    }
    if (this.d_iframeMode) {
      this.d_screenModeChangeAvailable = false;
    } else {
      if (this.p_screenModeChange == 'no') {
        this.d_screenModeChangeAvailable = false;
      }
    }
    if (this.p_projectId) {
      this.d_projectId = this.p_projectId;
    } else if (!this.p_projectId) {
      if (this.$route.query.project_id !== undefined) {
        this.d_projectId = this.$route.query.project_id;
      }
    }
    if (this.p_idType) {
      this.d_idType = this.p_idType;
    } else {
      if (this.$route.query.client_id !== undefined) {
        this.d_idType = 'client_id_based';
      }
      if (this.$route.query.wdmr_id !== undefined) {
        this.d_idType = 'based_on_wdmr_id';
      }
    }
    if (this.$route.query.client_id_show === 'no') {
      this.d_clientIdShow = false;
    }
    if (this.$route.query.sql_name_show === 'no') {
      this.d_showSqlName = false;
    }
    if (this.$route.query.client_id !== undefined) {
      this.d_clientId = this.$route.query.client_id;
    }
    if (this.$route.query.project_id !== undefined) {
      if (this.$route.query.project_id === this.d_projectId) {
        if (this.$route.query.sql_name !== undefined) {
          this.d_sqlName = this.$route.query.sql_name;
        }
      }
    }
    if (this.$route.query.screen_mode) {
      this.d_screen_mode = this.$route.query.screen_mode;
    } else {
      if (this.p_screenMode) {
        this.d_screen_mode = this.p_screenMode;
      }
    }
    this.f_wanalyzerProjectDetailsColumnsWaiGet();
    // if (this.d_screen_mode !== '2') {
    //   this.f_wanalyzerProjectDetailsColumnsWaiGet();
    // }

  },
  methods: {
    f_dateFormat: function (date, date_type='year-month-day') {
      if (date) {
        if (date_type === 'year-month-day') {
          if (this.StoreLang === 'tr') {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
            return x;
          } else {
            let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("YYYY/MM/DD");
            return x;
          }
        } else if (date_type === 'year-month-day hh:mm') {
          let x = moment(date, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY hh:mm");
          return x;
        }
      } else {
        return "";
      }
    },
    f_controlWaiOutputMode: function (col, output_show_type) {
      let show_outpul_col = false;
      if (col.wai_output === 1) {
        if (output_show_type === 'right') {
          if (col.style === undefined || (col.style && (col.style.waiLocation === undefined || col.style.waiLocation === 'right_any_location'))) {
            show_outpul_col = true;
          }
        } else if (output_show_type === 'bottom') {
          if (col.style && col.style.waiLocation === 'bottom_as_card') {
            show_outpul_col = true;
          }
        }
      }
      return show_outpul_col;
    },
    f_showTextAsModal: function (text) {
      this.d_selectedTableCellFullText = text.toString();
      this.d_showTableCellFullText = true;
    },
    f_calculateColumnStyle: function (column_data) {
      let column_style = 'width: 100%; padding: 3px;';
      // let column_style = 'width: 100%; padding: 3px; text-align: center;';
      // console.log('column_data.style : ', column_data.style);
      if (column_data.style && column_data.style.backgroundColor) {
        column_style += 'background: ' + column_data.style.backgroundColor + ';';
      }
      if (column_data.style && column_data.style.color) {
        column_style += 'color: ' + column_data.style.color + ';';
      }
      if (column_data.style && column_data.style.fontSize) {
        column_style += 'font-size: ' + column_data.style.fontSize + 'px;';
      }
      return column_style;
    },
    f_resetForm: function () {
      this.d_clientId = '';
      this.d_wdmrId = '';
      this.d_project.details = '';
      this.d_project.column_list = [];
      this.d_waiInputColIndexList = [];
      this.d_currentColIndex = 0;
      this.d_isAnalyzed = false;
      this.f_wanalyzerProjectDetailsColumnsWaiGet();
    },
    f_wanalyzerProjectDetailsColumnsWaiGet: function () {
      this.d_projectDetailsLoading = true;
      let query = 'project_id=' + this.d_projectId;
      this.d_isThereRightAnyLocationColumn = false;
      if (this.p_authClientId) {
        query += '&auth_client_id=' + this.p_authClientId ;
      }
      let data = {};
      ServiceFunctions.wanalyzer_project_details_columns_wai_get(query, data)
        .then(resp => {
          this.d_projectDetailsLoading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_project.details = resp.data.details;
            this.d_project.column_list = resp.data.column_list;

            for (let i in this.d_project.column_list) {
              if (this.d_project.column_list[i].wai_input === 1) {
                  this.d_isThereInputColumn = true;
                  this.d_waiInputColIndexList.push(i);
                  // break;
              }
              if (this.d_project.column_list[i].wai_output === 1) {
                if (this.d_project.column_list[i].style && this.d_project.column_list[i].style.waiLocation && this.d_project.column_list[i].style.waiLocation === 'right_any_location') {
                  this.d_isThereRightAnyLocationColumn = true;
                  break;
                }
              }
            }            
            let wait_for_input = false;
            if (this.d_clientId) {
              //
            } else {
              for (let i in this.d_project.column_list) {
                if (this.d_project.column_list[i].wai_input === 1) {
                    wait_for_input = true;
                    break;
                }
              }
            }
            if (!wait_for_input) {
              this.f_wanalyzerProjectCellCalculate();
            }
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_wanalyzerProjectCellCalculate: function () {
      let query = 'project_id=' + this.d_projectId;
      if (this.d_clientId) {
        query += '&client_id=' + this.d_clientId
      }
      if (this.d_wdmrId) {
        query += '&wdmr_id=' + this.d_wdmrId
      }
      if (this.p_authClientId) {
        query += '&auth_client_id=' + this.p_authClientId ;
      }

      let data = {
        'column_list': [],
        'sql_name': this.d_sqlName
      };
      let not_filled_det = false;
      for (let i in this.d_project.column_list) {
        if (this.d_project.column_list[i].wai_input === 1) {
          if (this.d_project.column_list[i].val) {
            let new_input_data = {
              'id': this.d_project.column_list[i].id,
              'label': this.d_project.column_list[i].name,
              'val': this.d_project.column_list[i].val ? this.d_project.column_list[i].val : ''
            }
            data.column_list.push(new_input_data);
          } else {
            if (this.d_clientId || this.d_wdmrId) {
              //
            } else {
              not_filled_det = true;
              break            
            }
          }
        }
      }
      if (not_filled_det) {
        alert(this.StoreLangTranslation.data['please_fill_all_inputs'][this.StoreLang]);
      } else {
        const currentColumnList = JSON.stringify(this.d_columnList);
        const newColumnList = JSON.stringify(data.column_list);
        if (currentColumnList === newColumnList && this.d_columnList.length) {
          alert(this.StoreLangTranslation.data['inputs_not_changed_alert'][this.StoreLang])
        } else {
          // console.log(this.d_columnList)
          this.d_columnList = JSON.parse(JSON.stringify(data.column_list));
          for (let cl_index in this.d_project.column_list) {
            if (this.d_project.column_list[cl_index].wai_output === 1) {
              this.d_project.column_list[cl_index].obj = '';
            }
          }
          this.d_analyzeContinuing = true;
          ServiceFunctions.wanalyzer_project_cell_calculate(query, data)
            .then(resp => {
              this.d_analyzeContinuing = false;
              if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
                for (let col_index in resp.data.column_list) {
                  if (resp.data.column_list[col_index].wai_output === 1) {
                    for (let cl_index in this.d_project.column_list) {
                      if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                        this.d_project.column_list[cl_index].obj = resp.data.column_list[col_index].obj;
                        if (resp.data.column_list[col_index].style !== undefined) {
                          this.d_project.column_list[cl_index].style = resp.data.column_list[col_index].style;
                        }
                        break;
                      }
                    }
                  } else if (resp.data.column_list[col_index].wai_input === 1) {
                    for (let cl_index in this.d_project.column_list) {
                      if (this.d_project.column_list[cl_index].name === resp.data.column_list[col_index].name) {
                        if (!this.d_project.column_list[cl_index].val) {
                          let input_val = '';
                          for (let k in resp.data.column_list[col_index].obj) {
                            for (let l_index in resp.data.column_list[col_index].obj[k].list) {
                              input_val = resp.data.column_list[col_index].obj[k].list[l_index].val;
                              break;
                            }
                            if (input_val) {
                              break;
                            }
                          }
                          this.d_project.column_list[cl_index].val = input_val;
                        }
                        break;
                      }
                    }                  
                  }
                }
                let time_based_list = [];
                for (let i in this.d_project.column_list) {
                  if (this.d_project.column_list[i].wai_output === 1) {
                    if (this.d_project.column_list[i].style && this.d_project.column_list[i].style.waiLocation && this.d_project.column_list[i].style.waiLocation === 'right_any_location') {
                      this.d_isThereRightAnyLocationColumn = true;
                      break;
                    }
                    if (this.d_project.column_list[i].obj) {
                      for (let obj_key in this.d_project.column_list[i].obj) {
                        let date_string = '';
                        if (this.d_project.column_list[i].obj[obj_key].date) {
                          date_string = this.d_project.column_list[i].obj[obj_key].date;
                        }
                        for (let obj_li_index in this.d_project.column_list[i].obj[obj_key].list) {
                          let obj_data = this.d_project.column_list[i].obj[obj_key].list[obj_li_index];
                          let new_val_item = {
                            'date': date_string,
                            'val': obj_data['val'],
                            'label': this.d_project.column_list[i].name,
                            'background_color': ''
                          };
                          if (this.d_project.column_list[i].style && this.d_project.column_list[i].style.backgroundColor) {
                            new_val_item.background_color = this.d_project.column_list[i].style.backgroundColor;
                          }
                          time_based_list.push(new_val_item);
                        }
                      }
                    }
                  }
                }
                let param = 'date';
                let asc_or_desc = 1;
                // let asc_or_desc = -1;
                function compare(a, b) {
                  // Use toUpperCase() to ignore character casing
                  let param_a = '';
                  let param_b = '';
                  try {
                    param_a = a[param].toLocaleLowerCase();
                    param_b = b[param].toLocaleLowerCase();
                  } catch (err) {
                    param_a = a[param];
                    param_b = b[param];
                  }

                  let comparison = 0;
                  if (param_a > param_b) {
                    comparison = 1;
                  } else if (param_a < param_b) {
                    comparison = -1;
                  }
                  return comparison * asc_or_desc;
                }
                time_based_list.sort(compare);
                // console.log('time_based_list : ', time_based_list);
                if (time_based_list.length > 0) {
                  this.d_timeLineLeftToRight = [];
                  // first row is year-month-day based.
                  // [[{}], [{}], [{"date": "2021-12-6"}], [{"date": "2021-12-7"}], [{"date": "2021-12-16"}], [{"date": "2021-12-27"}], [{"date": "2022-1-4"}] ]
                  let first_row = [[{}], [{}]];
                  let unique_date_list = [];
                  let unique_column_label_list = [];
                  for (let t_index in time_based_list) {
                    if (unique_column_label_list.indexOf(time_based_list[t_index].label) === -1) {
                      unique_column_label_list.push(time_based_list[t_index].label);
                    }
                    let year_month_day = '';
                    if (time_based_list[t_index].date) {
                      if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                        year_month_day = time_based_list[t_index].date.split(' ')[0];
                      } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                        year_month_day = time_based_list[t_index].date.split('T')[0];
                      }
                    }
                    if (year_month_day && unique_date_list.indexOf(year_month_day) === -1) {
                      unique_date_list.push(year_month_day);
                    }
                  }
                  // console.log('unique_date_list : ', unique_date_list);
                  for (let unique_ind in unique_date_list) {
                    first_row.push([{'date': unique_date_list[unique_ind]}]);
                  }
                  this.d_timeLineLeftToRight.push(first_row);
                  for (let unique_ind in unique_column_label_list) {
                    let first_col = {
                      "background_color": "#e5f7f3",
                      "value": unique_column_label_list[unique_ind],
                      "label": unique_column_label_list[unique_ind]
                    };
                    for (let cl_index in this.d_project.column_list) {
                      if (this.d_project.column_list[cl_index].name === unique_column_label_list[unique_ind]) {
                        if (this.d_project.column_list[cl_index].style && this.d_project.column_list[cl_index].style.backgroundColor) {
                          first_col.background_color = this.d_project.column_list[cl_index].style.backgroundColor;
                        }
                        break;
                      }
                    }
                    let new_row = [[first_col], []];
                    for (let i = 0; i < unique_date_list.length; i++) {
                      new_row.push([]);
                    }
                    this.d_timeLineLeftToRight.push(new_row);
                  }
                }
                for (let row_index in this.d_timeLineLeftToRight) {
                  if (row_index > 0) {
                    let row_label = this.d_timeLineLeftToRight[row_index][0][0]['label'];
                    for (let col_index in this.d_timeLineLeftToRight[row_index]) {
                      if (parseInt(col_index) === 1) {
                        for (let t_index in time_based_list) {
                          if (!time_based_list[t_index].date) {
                            let cell_value = '';
                            let cell_label = '';
                            if (typeof(time_based_list[t_index].val) === 'object') {
                              cell_value = time_based_list[t_index].val.value;
                              cell_label = time_based_list[t_index].val.label;
                            } else {
                              cell_value = time_based_list[t_index].val;
                              cell_label = time_based_list[t_index].val;                              
                            }
                            if (row_label === time_based_list[t_index].label) {
                              let new_cell_item = {
                                'label': cell_label,
                                'value': cell_value,
                                'date': '',
                                'background_color': time_based_list[t_index].background_color
                              };
                              this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                            }
                          }
                        }
                      } else if (parseInt(col_index) > 1) {
                        let col_date = this.d_timeLineLeftToRight[0][col_index][0]['date'];
                        // row_label ve col_date olan verileri time_based_list içerisinden ekleyeceğiz.
                        for (let t_index in time_based_list) {
                          let year_month_day = '';
                          if (time_based_list[t_index].date) {
                            if (time_based_list[t_index].date.indexOf(' ') !== -1) {
                              year_month_day = time_based_list[t_index].date.split(' ')[0];
                            } else if (time_based_list[t_index].date.indexOf('T') !== -1) {
                              year_month_day = time_based_list[t_index].date.split('T')[0];
                            }
                          }
                          if (year_month_day) {
                            if (col_date === year_month_day) {
                              let cell_value = '';
                              let cell_label = '';
                              if (typeof(time_based_list[t_index].val) === 'object') {
                                cell_value = time_based_list[t_index].val.value;
                                cell_label = time_based_list[t_index].val.label;
                              } else {
                                cell_value = time_based_list[t_index].val;
                                cell_label = time_based_list[t_index].val;                              
                              }
                              if (row_label === time_based_list[t_index].label) {
                                let new_cell_item = {
                                  'label': cell_label,
                                  'value': cell_value,
                                  'date': time_based_list[t_index].date,
                                  'background_color': time_based_list[t_index].background_color
                                };
                                this.d_timeLineLeftToRight[row_index][col_index].push(new_cell_item);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                // console.log('this.d_timeLineLeftToRight : ', this.d_timeLineLeftToRight);

                for (let i in this.d_project.column_list) {
                  if (this.d_project.column_list[i].obj) {
                    let obj_item_list = [];
                    for (let obj_key in this.d_project.column_list[i].obj) {
                      let obj_date = '';
                      if (this.d_project.column_list[i].obj[obj_key].date) {
                        obj_date = this.d_project.column_list[i].obj[obj_key].date;
                      }
                      let new_obj_item = {
                        'date': obj_date,
                        'key': obj_key
                      };
                      obj_item_list.push(new_obj_item);
                    }
                    let param = 'date';
                    let asc_or_desc = 1;
                    // let asc_or_desc = -1;
                    function compare(a, b) {
                      // Use toUpperCase() to ignore character casing
                      let param_a = '';
                      let param_b = '';
                      try {
                        param_a = a[param].toLocaleLowerCase();
                        param_b = b[param].toLocaleLowerCase();
                      } catch (err) {
                        param_a = a[param];
                        param_b = b[param];
                      }

                      let comparison = 0;
                      if (param_a > param_b) {
                        comparison = 1;
                      } else if (param_a < param_b) {
                        comparison = -1;
                      }
                      return comparison * asc_or_desc;
                    }
                    obj_item_list.sort(compare);
                    let new_obj_order = [];
                    for (let obj_ind in obj_item_list) {
                      new_obj_order.push(obj_item_list[obj_ind].key);
                    }
                    this.d_project.column_list[i].obj_order = new_obj_order;
                  }
                }
                this.d_isAnalyzed = true;
                this.d_showInputs = false;
                this.$forceUpdate();
              } else {
                let txt = 'status_code = ' + resp.data.status_code + '\n';
                txt += 'status_message = ' + resp.data.status_message;
                alert(txt);
              }
            });
          }
        }
    },
    f_nextIndex: function (columnValue) {
      if (columnValue) {
        if (this.d_currentColIndex != this.d_waiInputColIndexList.length - 1) {
          this.d_columnInputWait = true;
          setTimeout(() => {
            this.d_currentColIndex ++;
            this.d_columnInputWait = false;
          }, 300)
          // this.d_currentColIndex ++;
        } else if (!this.d_isAnalyzed) {
          if (!this.d_analyzeContinuing) {
            this.f_wanalyzerProjectCellCalculate();
          } else {
            alert(this.StoreLangTranslation.data['analyze_continuing'][this.StoreLang])
          }
        }
        this.$forceUpdate();
      } else {
        alert(this.StoreLangTranslation.data['empty_input_alert'][this.StoreLang])
      }
    },
    f_columnButtonAndNextIndex: function (value) {
      this.d_project.column_list[this.d_waiInputColIndexList[this.d_currentColIndex]].val = value;
      this.f_nextIndex(value);
    },
    f_analyzeIfNotAnalyzeContinuing: function () {
      if (!this.d_analyzeContinuing) {
        this.f_wanalyzerProjectCellCalculate();
      } else {
        alert(this.StoreLangTranslation.data['analyze_continuing'][this.StoreLang])
      }
    }
  },
  watch: {
    'd_clientId': function () {
      // this.f_resetForm();
    }
  }
}

</script>

